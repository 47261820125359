<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Applications</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Applications"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              light
              :to="{ name: 'module-lhl-applications-puppy' }"
            >
              <v-icon small left>mdi-arrow-left</v-icon> Back to Applications
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="tableHeaders"
            :items="applications"
            no-data-text="No Archived applications"
          >
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    class="mr-2"
                    color="yellow lighten-4 yellow--text text--darken-3"
                    v-on="on"
                    @click="openRestore(item)"
                  >
                    <v-icon small>mdi-restore</v-icon>
                  </v-btn>
                </template>
                <span>Restore</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      scrollable
      v-model="restoreDialog.open"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Restore Application</v-card-title>
        <v-card-text
          >Are you sure you want to restore this application?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetRestore"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="restoreDialog.loading"
            @click="saveRestore"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: String,
  },
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Applications",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],
      restoreDialog: {
        open: false,
        loading: false,
        application: {},
      },
      tableHeaders: [
        { text: "Name", value: "admins.full_name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    applications() {
      let applications = this.$store.getters["lhl/applications/archived"];

      return applications;
    },
  },

  methods: {
    openRestore(application) {
      this.restoreDialog.application = application;
      this.restoreDialog.open = true;
    },

    resetRestore() {
      this.restoreDialog.loading = false;
      this.restoreDialog.open = false;
      this.restoreDialog.application = {};
    },

    saveRestore() {
      const appId = this.$route.params.id;
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("lhl/applications/restoreApplication", {
          appId,
          applicationId: this.restoreDialog.application.id,
        })
        .then(() => this.resetRestore())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
